import { BaseConfig, Config, useBook } from 'topwrite';
import { Config as ConfigType } from './config';

declare const PLUGIN_NAME: string;

declare module 'topwrite' {
    export interface HookContext {
        getConfig(): BaseConfig<ConfigType>;
    }
}

export function getPluginConfig(config: Config): BaseConfig<ConfigType> {

    const schema = require('../config.json');

    return config.getPluginConfig(PLUGIN_NAME, schema);
}

export default function useConfig() {
    const { config } = useBook();

    return getPluginConfig(config);
};
