import { ComponentsMap, GetComponentType, Plugin, PluginConfig, PluginMeta } from 'topwrite';
import { ComponentType, lazy } from 'react';

declare const PLUGIN_NAME: string;
declare const PLUGIN_META: PluginMeta | undefined;
declare const SUPPORT_CONFIG: boolean;
declare const SUPPORT_LANG: boolean;

type LazyType<T extends ComponentType<any>> = () => Promise<{ default: T }>;

type LazyCompType<index extends keyof ComponentsMap> = LazyType<GetComponentType<index>>;

export type LazyComponentsMap = {
    [index in keyof ComponentsMap]?: LazyCompType<index> | [LazyCompType<index>, { priority?: number }]
}

export interface PluginConstructorParams extends Omit<PluginConfig, 'name' | 'meta' | 'components' | 'config'> {
    locales?: string[];
    components?: LazyComponentsMap;
}

export default function createPlugin({
    components: lazyComponents,
    localize,
    locales,
    ...others
}: PluginConstructorParams) {
    let config;
    if (SUPPORT_CONFIG) {
        config = require('../config.json');
    }

    if (SUPPORT_LANG) {
        if (!localize && locales) {
            localize = (locale) => {
                if (locales.includes(locale)) {
                    return import(`../src/lang/${locale}.json`);
                }
                return import(`../src/lang/${locales[0]}.json`);
            };
        }
    }

    let components: Partial<ComponentsMap> = {};
    if (lazyComponents) {
        for (const [name, component] of Object.entries(lazyComponents)) {
            if (component) {
                if (component instanceof Array) {
                    components[name] = [lazy(component[0]), component[1]];
                } else {
                    components[name] = lazy(component);
                }
            }
        }
    }

    return new Plugin({
        name: PLUGIN_NAME,
        meta: PLUGIN_META,
        components,
        localize,
        config,
        ...others
    });
}
