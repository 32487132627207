import { getPayload, request, RequestConfig, Summary, SummaryArticle } from 'topwrite';
import path from 'path';

interface BookData {
    hash_id: string;
    sha: string;
    name: string;
    slug?: string;
}

export default class SubBook {
    readonly host: string;
    public ids: string[] = [];

    protected lfsFiles: Record<string, string> = {};

    protected slugs: Record<string, string> = {};

    protected books: Record<string, BookData> = {};

    constructor(host: string) {
        this.host = host;
    }

    setLfsFiles(lfsFiles: Record<string, string>) {
        this.lfsFiles = lfsFiles;
    }

    async getBooks(query: string): Promise<BookData[]> {
        try {
            const { data: { data } } = await request(`${this.host}/api/book`, { params: { q: query } });

            return data;
        } catch {
            return [];
        }
    }

    async getBook(id: string): Promise<BookData | undefined> {
        if (this.books[id]) {
            return this.books[id];
        }

        try {
            const { data } = await request(`${this.host}/api/book/${id}`);
            this.books[id] = data;
            return data;
        } catch {

        }
    }

    protected resolveUrl(id: string, url: string = '') {
        if (!this.host) {
            return `/${url}`;
        }
        return `${this.host}/@${this.resolveSlug(id)}/${url}`;
    }

    protected resolveSlug(id: string) {
        return this.slugs[id] || id;
    }

    resolvePath(id: string, ref: string) {
        return path.join(`${this.resolveSlug(id)}`, ref);
    }

    async getArticles(book: BookData) {
        try {
            const id = book.hash_id;

            if (book.slug) {
                this.slugs[id] = book.slug;
            }

            const { data } = await request(this.resolveUrl(id));

            this.ids.push(id);

            const payload = typeof data === 'string' ? getPayload(data) : data;

            const summary = Summary.create(payload.summary);

            summary.getArticle((article) => {
                if (article.ref) {
                    article.ref = this.resolvePath(id, article.ref);
                }
                if (article.path) {
                    article.path = this.resolvePath(id, article.path);
                }
                return false;
            });

            return summary.parts.reduce<SummaryArticle[]>((articles, part) => {
                articles.push(...part.articles);

                return articles;
            }, []);
        } catch (e) {
            console.warn(e);
        }
    }

    validateUrl(url?: string) {
        if (url) {
            for (const id of Object.values(this.slugs).concat(this.ids)) {
                const reg = new RegExp(`/${id}/`);
                const matches = reg.exec(url);
                if (matches) {
                    return [this.resolveSlug(id), this.resolveUrl(id, url.substring(matches.index + id.length + 2))];
                }
            }
        }
    }

    resolveBook(path: string) {
        for (const [id, slug] of Object.entries(this.slugs)) {
            if (slug === path) {
                return id;
            }
        }

        if (this.ids.includes(path)) {
            return path;
        }
    }

    interceptRequest() {
        return (config: RequestConfig) => {
            const result = this.validateUrl(config.url);

            if (result) {
                const [slug, url] = result;
                config.url = url;

                config.transformResponse = [
                    ...config.transformResponse as any,
                    (data) => {
                        if (typeof data === 'string') {
                            data = getPayload(data);
                        }

                        if (data.file.path) {
                            data.file.path = this.resolvePath(slug, data.file.path);
                        }

                        if (data.lfs) {
                            data.lfs.files = {
                                ...this.lfsFiles,
                                ...Object.fromEntries(
                                    Object.entries(data.lfs.files)
                                          .map(([key, value]) => {
                                              return [this.resolvePath(slug, key), value];
                                          })
                                ),
                            };
                        }
                        return data;
                    }
                ];
            }
            return config;
        };
    }
}

let subBook: SubBook;

export function createSubBook(host: string) {
    return subBook = new SubBook(host);
}

export function useSubBook() {
    return subBook;
}
